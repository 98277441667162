<template>
  <div class='payment-summary' ref="paymentSummary">
    <error-toast :isVisible="errorToast.isVisible" :text="errorToast.text" />
    <div class="summary-section section">
      <div class="section-header">
        <h3 class="title">{{ $t('pages.payment.tabs.summary.content.invoiceData.title') }}</h3>
        <base-button
          :isSecondary="true"
          :text="$t('pages.payment.tabs.summary.content.buttons.modify.text')"
          @clicked="handleHeaderBtnClick('invoice')"
        />
      </div>
      <div class="section-content">
        <div class="field" v-for="(item, index) in invoiceFields" :key="index">
          <span class="label">{{ item.label }}</span>
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
    </div>
    <div class="tickets-section section">
      <div class="section-header">
        <h3 class="title">{{ $t('pages.payment.tabs.summary.content.tickets.title') }}</h3>
        <base-button
          :isSecondary="true"
          :text="$t('pages.payment.tabs.summary.content.buttons.modify.text')"
          @clicked="handleHeaderBtnClick('summary')"
        />
      </div>
      <div v-if="getCurrentCart" class="section-content">
        <div class="cart-item-group" v-for="(cartItem, i) in getCurrentCart.cartItems" :key="i">
          <span class="date">{{ cartItem.date }}</span>
          <div class="item-row" v-for="(item, index) in cartItem.cikkList" :key="index">
            <div class="left-col">
              <span class="ticket-zone" :style="`color: ${item.cikk.facilityColor}`">{{ item.cikk.cikkcsop_nev }}</span>
              <span class="ticket-name">{{ item.cikk.cikk_nev }}</span>
            </div>
            <div class="middle-col">
              <span class="ticket-amount">{{ item.amount }}</span>
            </div>
            <div class="right-col">
              <span class="price">{{ item.amount * item.cikk.cikkar.cikkar_bruttoegysegar | toCurrency }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="price-section">
      <span class="label">{{ $t('pages.payment.tabs.summary.content.tickets.price.summary.label') }}</span>
      <span class="value">{{ getCurrentCart.price.grossUnitPrice | toCurrency }}</span>
    </div>
    <div class="checkbox-section">
      <base-checkbox
        :label="$t(checkbox.labelKey)"
        :isChecked="checkbox.isChecked"
        :hasError="checkbox.hasError"
        @changed="handleCheckboxInput"
      />
    </div>
    <div class="buttons-section">
      <a :href="getsimpleLink()" target="_blank">
        <img src="/static/images/logos/simplepay-w-bankcards.jpg" class="simple-logo">
      </a>
      <div class="row" style="justify-content: center;" v-if="providers">
        <div class="col-6" v-if="providers.simple === 1">
          <base-button
          :isLoading="isPayBtnLoading"
          :text="$t('pages.payment.tabs.summary.content.buttons.pay.providers.simple')"
          @clicked="handlePayBtnClick($PAYMENT_PROVIDERS.PROVIDER.SIMPLE)"
          />
        </div>
        <div class="col-6" v-if="providers.simple_szep === 1">
          <base-button
          :isLoading="isPayBtnLoading"
          :text="$t('pages.payment.tabs.summary.content.buttons.pay.providers.simple_szep')"
          @clicked="handlePayBtnClick($PAYMENT_PROVIDERS.PROVIDER.SIMPLE_SZEP)"
          />
        </div>
        <div v-if="providers.simple_szep === 1" class="refund-warning">{{ $t('pages.payment.tabs.summary.content.refundWarning') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import store from '@/store';
import scroll from '@/components/shared/mixins/scroll';

import BaseButton from '@/components/shared/elements/buttons/ButtonBase';
import BaseCheckbox from '@/components/shared/elements/inputs/BaseCheckbox';

export default {
  name: 'PaymentSummary',
  mixins: [scroll],
  props: {},
  components: {
    BaseButton,
    BaseCheckbox,
    'error-toast': () => import('@/components/shared/elements/toasts/ErrorToast'),
  },
  data: () => ({
    errorToast: {
      isVisible: false,
      text: undefined,
    },
    checkbox: {
      labelKey: 'pages.payment.tabs.summary.content.tickets.checkbox.label',
      isChecked: false,
      hasError: false,
    },
    isPayBtnLoading: false,
    providers: undefined,
  }),
  created() {
    this.getProviders().then((response) => {
      this.providers = response.data;
    });
  },
  computed: {
    ...mapGetters({
      loggedInUserProfile: 'users/getLoggedInUserProfile',
      getCurrentCart: 'cart/getCurrentCart',
    }),
    selectedInvoiceData() {
      if (this.getCurrentCart) {
        return this.getCurrentCart.invoiceData;
      }
      return undefined;
    },
    invoiceFields() {
      let result;

      if (this.getCurrentCart) {
        result = [
          {
            label: this.$t('pages.registration.form.sections.personal.labels.firstName'),
            value: this.loggedInUserProfile.firstName,
          },
          {
            label: this.$t('pages.registration.form.sections.personal.labels.lastName'),
            value: this.loggedInUserProfile.lastName,
          },
          {
            label: this.$t('pages.payment.tabs.summary.content.invoiceData.fields.taxNumber'),
            value: this.selectedInvoiceData.taxNumber,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.country'),
            value: this.selectedInvoiceData.country,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.city'),
            value: this.selectedInvoiceData.cityName,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.zipCode'),
            value: this.selectedInvoiceData.zipCode,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.publicSpaceName'),
            value: this.selectedInvoiceData.publicSpaceName,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.publicSpaceType'),
            value: this.selectedInvoiceData.publicSpaceType,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.houseNumber'),
            value: this.selectedInvoiceData.houseNumber,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.building'),
            value: this.selectedInvoiceData.building,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.stairway'),
            value: this.selectedInvoiceData.stairwayNumber,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.floor'),
            value: this.selectedInvoiceData.floor,
          },
          {
            label: this.$t('pages.registration.form.sections.invoiceData.labels.door'),
            value: this.selectedInvoiceData.door,
          },
        ];

        if (this.selectedInvoiceData.isCompany) {
          result.splice(0, 2); // replace first and last name with company name when the selected invoice is company

          result.unshift({
            label: this.$t('pages.payment.tabs.summary.content.invoiceData.fields.companyName'),
            value: this.selectedInvoiceData.companyName,
          });
        } else {
          result.splice(2, 1); // remove tax number when the selected invoice is personal
        }
      }

      return result.filter((item) => item.value.length !== 0 && item.value !== undefined);
    },
  },
  methods: {
    ...mapActions({
      finalizeCart: 'cart/finalizeCart',
      getProviders: 'settings/getProviders',
    }),
    getsimpleLink() {
      const language = store.getters.langCode;
      if (language === 'hu' || language === undefined) {
        return process.env.VUE_APP_SIMPLE_URL_HU;
      }
      return process.env.VUE_APP_SIMPLE_URL_FOREIGN;
    },
    handleCheckboxInput(payloadValue) {
      this.checkbox.isChecked = payloadValue;
    },
    validateCheckbox() {
      this.checkbox.hasError = !this.checkbox.isChecked;

      return !this.checkbox.hasError;
    },
    handlePayBtnClick(provider) {
      const isValid = this.validateCheckbox();

      if (isValid) {
        this.isPayBtnLoading = true;

        // TODO: empty + remove cart from store
        this.finalizeCart({ cartId: this.getCurrentCart._id, provider }).then((resp) => {
          this.isPayBtnLoading = false;
          window.location.href = resp.paymentUrl;
        }).catch((err) => {
          this.isPayBtnLoading = false;
          this.errorToast.isVisible = true;
          this.errorToast.text = err.data.message;

          this.scrollToToast('paymentSummary');
        });
      }
    },
    handleHeaderBtnClick(section) {
      switch (section) {
        case 'invoice':
          this.$emit('switchToTab', 1);
          break;
        case 'summary':
          this.$router.push('/cart');
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.error-toast {
  margin: 0 0 20px;
}

.section {
  margin: 0 0 25px;
}

.section-header {
  display: flex;
  align-items: center;
  margin: 0 0 15px;

  @media screen and (max-width: $breakpointDownXXs) {
    flex-wrap: wrap;
  }

  .title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    margin: 0;

    @media screen and (max-width: $breakpointDownXXs) {
      display: block;
      width: 100%;
    }
  }

  .button-base {
    margin: 0 auto 0 15px;
    box-shadow: none;
    height: 25px;
    line-height: 25px;
    padding: 0 20px;

    @media screen and (max-width: $breakpointDownXXs) {
      margin: 5px 0 0;
    }

    &:hover {
      background-color: darken($secondaryButtonBackground, 10%);
    }
  }
}

.summary-section {
  .section-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;

    @media screen and (max-width: $breakpointDownXs) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .field {
    font-size: 15px;
    font-weight: 500;

    @media screen and (max-width: $breakpointDownXs) {
      font-size: 14px;
    }

    span {
      display: block;
      line-height: 1.2;
    }

    .label {
      margin: 0 0 5px;
    }

    .value {
      font-weight: 700;
      padding-left: 8px;
    }
  }
}

.cart-item-group {
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  .date {
    display: block;
    font-weight: 700;
    font-size: 15px;
    margin: 0 0 8px;
    line-height: 1.2;
  }

  .item-row {
    margin: 0 0 8px;
    display: grid;
    grid-template-columns: 1.8fr auto 0.8fr;
    column-gap: 35px;

    @media screen and (max-width: $breakpointDownXXs) {
      font-size: 14px;
    }

    .ticket-zone,
    .ticket-name {
      display: block;
      line-height: 1.2;
    }

    .ticket-amount,
    .ticket-zone,
    .price {
      font-weight: 700;
    }

    .ticket-name {
      font-weight: 500;
    }

    .right-col {
      text-align: right;
    }

    .price {
      color: $primaryBlue;
    }
  }
}

.price-section {
  text-align: center;
  font-size: 20px;

  .label {
    font-weight: 500;
    margin: 0 20px 0 0;
    display: inline-block;
  }

  .value {
    color: $primaryBlue;
    font-weight: 700;
  }
}

.checkbox-section {
  margin: 40px 0 0;
  display: flex;
  justify-content: center;

  .base-checkbox {
    &::v-deep {
      .label {
        font-size: 10px;

        @media screen and (max-width: $breakpointDownXs) {
          font-size: 12px;
        }
      }

      .input-wrapper {
        @media screen and (max-width: $breakpointDownXs) {
          flex: 1 0 auto;
        }
      }
    }
  }
}

.buttons-section {
  margin: 8px 0 0;
  .refund-warning {
    margin-top: 15px;
    font-size: 10px;
    text-align: center;
  }

  .simple-logo {
    margin: 15px auto;
    display: block;
    max-height: 28px;
    max-width: 100%;
    image-rendering: -moz-crisp-edges;
    image-rendering:   -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }

  .button-base {
    text-transform: uppercase;
    min-width: 150px;
    padding: 0 35px;
  }
}
</style>
